<template>
  <div id="app" class="auth">
    <vue-confirm-dialog></vue-confirm-dialog>
    <Header :auth="auth"></Header>

    <div class="wrapper-view">
      <router-view v-if="loaded" />
    </div>
  </div>
</template>

<script>
import Header from "./components/Header";
import { mapState, mapActions } from "vuex";

export default {
  name: "Main",
  components: {
    Header,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState(["auth", "user"]),
  },
  created() {
    // reload user info
    this.reloadUserInfo();
  },
  methods: {
    ...mapActions(["getBusinessList", "getCurrentUser"]),
    async reloadUserInfo() {
      if (this.auth) {
        if (localStorage.getItem("token") !== null) {
          await this.getCurrentUser();
          let _hsq = window._hsq = window._hsq || [];
          _hsq.push(["identify", {
            email: this.user.email,
            id: this.user.id
          }]);
          this.loaded = true;
          if (this.user.account_role === 1) {
            await this.getBusinessList();
          }
        }
      } else {
        setTimeout(() => {
          this.loaded = true;
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;

  &.auth {
    padding: 90px 5px 0;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
      padding: 90px 5px 0;
      box-sizing: border-box !important;
    }
  }

  .footer {
    position: relative;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 0;
    padding: 0 30px;
    box-sizing: border-box;
    background: $color_background;

    p {
      font-family: "Gilroy", sans-serif;
      font-style: normal;
      font-size: 15px;
      line-height: 15px;
      color: #848282;

      @media screen and (max-width: 1201px) {
        padding-bottom: 15px;
      }
    }

    .company-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 45px;
      padding: 0;
      margin: 0;
      list-style-type: none;

      @media screen and (max-width: 1201px) {
        margin-bottom: 10px;
      }

      li {
        height: 45px;
        line-height: 27px;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 15px;
        padding: 0;

        a {
          .icon-footer {
            display: block;
            width: 25px;
            height: 25px;
            cursor: pointer;
            margin: 0 5px;
          }
        }
      }
    }

    @media screen and (max-width: 1201px) {
      width: 100%;
      flex-direction: column-reverse;
    }
  }

  .wrapper-view {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 165px);
    background: transparent;
    position: relative;
    box-sizing: border-box;

    @media screen and (max-width: 850px) {
      min-height: calc(100vh - 210px);
    }
  }
}
</style>
