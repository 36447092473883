import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
  getBusinessList() {
    return axios
      .get(window.$getRoute("get_business_list") + "?limit=500&offset=0")
      .then((response) => {
        let business_options = [];
        business_options = response.data.results.map((item) => {
          return {
            label: item.title,
            code: item.id,
          };
        });
        return business_options;
      });
  },
  getCurrentUser() {
    return axios.get(window.$getRoute("get_user_info")).then((response) => {
      return response.data;
    });
  },
  getCurrentInfluencer() {
    return axios
      .get(window.$getRoute("get_influencer_info"))
      .then((response) => {
        return response.data;
      });
  },
};
