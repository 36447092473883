<template>
  <div class="truncate-text-wrapper">
    <template v-if="popover && text.length > length">
      <span :id="`truncated-text-${computedId}`" :class="customClass">{{
        text | truncate(parseInt(length), symbols)
      }}</span>
      <b-popover
        :target="`truncated-text-${computedId}`"
        :placement="popoverPlacement"
        triggers="hover focus"
        :content="text"
        :custom-class="textAlignClass"
      ></b-popover>
    </template>
    <template v-else>
      <span :class="customClass">{{
        text | truncate(parseInt(length), symbols)
      }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: "TruncateText",
  props: {
    text: {
      type: String,
      required: true,
    },
    popover: {
      type: Boolean,
      default: true,
    },
    length: {
      type: [String, Number],
      default: 10,
    },
    symbols: {
      type: String,
      default: "...",
    },
    customClass: {
      type: String,
      default: "",
    },
    textAlignClass: {
      type: String,
      default: "popover-text-left",
    },
    popoverPlacement: {
      type: String,
      default: "topright",
    },
    id: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedId() {
      return this.id === null ? this._.uniqueId("uniqid_") : this.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate-text-wrapper {
  display: inline-block;
}

.popover-text-center {
  text-align: center;
  margin-top: -54px;
  word-break: break-all;
}

.popover-text-left {
  text-align: left;
  margin-top: -54px;
  word-break: break-all;
}
</style>
