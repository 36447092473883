import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/brief/:id",
    name: "Brief",
    component: () =>
      import(/* webpackChunkName: "Brief" */ "../views/Brief.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () =>
      import(/* webpackChunkName: "Logout" */ "../views/Logout.vue"),
  },
  {
    path: "/forgot/password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* ForgotPassword: "ForgotPassword" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/restore/password/:code",
    name: "RestorePassword",
    component: () =>
      import(
        /* RestorePassword: "RestorePassword" */ "../views/RestorePassword.vue"
      ),
  },
  {
    path: "/creator",
    name: "creator",
    component: () =>
      import(/* webpackChunkName: "emptyView" */ "../views/EmptyView.vue"),
    children: [
      {
        path: "login",
        name: "CreatorLogin",
        component: () =>
          import(
            /* webpackChunkName: "creatorLogin" */ "../views/creator/CreatorLogin.vue"
          ),
      },
      {
        path: "signup",
        name: "CreatorSignup",
        component: () =>
          import(
            /* webpackChunkName: "CreatorSignUp" */ "../views/creator/CreatorSignUp.vue"
          ),
      },
      {
        path: "signup/success",
        name: "CreatorSignupSuccessPage",
        component: () =>
          import(
            /* webpackChunkName: "CreatorSignupSuccessPage" */ "../views/creator/CreatorSignUpSuccessPage.vue"
          ),
      },
      {
        path: "confirm/:code",
        name: "CreatorConfirm",
        component: () =>
          import(
            /* webpackChunkName: "CreatorConfirm" */ "../views/creator/CreatorConfirm.vue"
          ),
      },
      {
        path: "signup/step/2",
        name: "CreatorSignupStepSecond",
        component: () =>
          import(
            /* webpackChunkName: "CreatorSignUp" */ "../views/creator/CreatorSignUpStepSecond.vue"
          ),
      },
      {
        path: "welcome",
        name: "CreatorWelcome",
        component: () =>
          import(
            /* webpackChunkName: "CreatorWelcome" */ "../views/creator/CreatorWelcome.vue"
          ),
      },
      {
        path: "participate/in/:id/campaign",
        name: "CreatorParticipateInCampaign",
        component: () =>
          import(
            /* webpackChunkName: "CreatorParticipateInCampaign" */ "../views/creator/CreatorParticipateInCampaign.vue"
          ),
      },
      {
        path: "campaigns",
        name: "CreatorCampaignsList",
        component: () =>
          import(
            /* webpackChunkName: "CreatorCampaignsList" */ "../views/creator/CreatorCampaignsList.vue"
          ),
      },
      {
        path: "deliverable/:id",
        name: "CreatorDelivery",
        meta: {
          company_name: "TEST",
        },
        component: () =>
          import(
            /* webpackChunkName: "CreatorDelivery" */ "../views/creator/CreatorDelivery.vue"
          ),
      },
      {
        path: "edit/profile",
        name: "CreatorEditProfile",
        component: () =>
          import(
            /* webpackChunkName: "CreatorEditProfile" */ "../views/creator/CreatorEditProfile"
          ),
      },
    ],
  },

  {
    path: "/business",
    name: "business",
    component: () =>
      import(/* webpackChunkName: "emptyView" */ "../views/EmptyView.vue"),
    children: [
      {
        path: "login",
        name: "BusinessLogin",
        component: () =>
          import(
            /* webpackChunkName: "businessLogin" */ "../views/business/BusinessLogin.vue"
          ),
      },
      {
        path: "content/gallery/download/:token",
        name: "BusinessDownloadContentGallery",
        component: () =>
          import(
            /* webpackChunkName: "BusinessDownloadContentGallery" */ "../views/business/BusinessDownloadContentGallery"
          ),
      },
      {
        path: "entry",
        name: "BusinessEntry",
        component: () =>
          import(
            /* webpackChunkName: "businessEntry" */ "../views/business/BusinessEntry.vue"
          ),
      },
      {
        path: "signup",
        name: "BusinessSignup",
        component: () =>
          import(
            /* webpackChunkName: "BusinessSignUp" */ "../views/business/BusinessSignUp.vue"
          ),
      },
      {
        path: "signup/success",
        name: "BusinessSignUpSuccessPage",
        component: () =>
          import(
            /* webpackChunkName: "BusinessSignUpSuccessPage" */ "../views/business/BusinessSignUpSuccessPage.vue"
          ),
      },
      {
        path: "confirm/:code",
        name: "BusinessConfirm",
        component: () =>
          import(
            /* webpackChunkName: "BusinessConfirm" */ "../views/business/BusinessConfirm.vue"
          ),
      },
      {
        path: "signup/step/2",
        name: "BusinessSignupStepSecond",
        component: () =>
          import(
            /* webpackChunkName: "BusinessSignupStepSecond" */ "../views/business/BusinessSignUpStepSecond.vue"
          ),
      },
      {
        path: "create/new/business",
        name: "BusinessCreateNewBusiness",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCreateNewBusiness" */ "../views/business/CreateNewBusiness.vue"
          ),
      },
      {
        path: "edit/business",
        name: "BusinessEditCurrentBusiness",
        component: () =>
          import(
            /* webpackChunkName: "BusinessEditCurrentBusiness" */ "../views/business/BusinessEditCurrentBusiness"
          ),
      },
      {
        path: "campaigns",
        name: "BusinessCampaignList",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCampaignList" */ "../views/business/BusinessCampaignList.vue"
          ),
      },
      {
        path: "campaign/:id",
        name: "BusinessCampaignInfo",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCampaignInfo" */ "../views/business/BusinessCampaignInfo.vue"
          ),
      },
      {
        path: "campaign/:campaign_id/delivery/:id",
        name: "BusinessCampaignDelivery",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCampaignDelivery" */ "../views/business/BusinessDelivery.vue"
          ),
      },
      {
        path: "create/campaign/",
        name: "BusinessCreateCampaign",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCreateCampaign" */ "../views/business/BusinessCreateCampaign.vue"
          ),
      },
      {
        path: "create/campaign/:id",
        name: "BusinessCreateCampaignContinue",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCreateCampaign" */ "../views/business/BusinessCreateCampaign.vue"
          ),
      },
      {
        path: "campaigns/:id/briefing",
        name: "BusinessEditBrief",
        component: () =>
          import(
            /* webpackChunkName: "BusinessEditBrief" */ "../views/business/BusinessCreateCampaign.vue"
          ),
      },
      {
        path: "sourcing",
        name: "BusinessSourcingList",
        component: () =>
          import(
            /* webpackChunkName: "BusinessSourcingList" */ "../views/business/BusinessSourcingList.vue"
          ),
      },
      {
        path: "edit/profile",
        name: "BusinessEditProfile",
        component: () =>
          import(
            /* webpackChunkName: "BusinessEditProfile" */ "../views/business/BusinessEditProfile.vue"
          ),
      },
      {
        path: "create/sourcing",
        name: "BusinessCreateSourcing",
        component: () =>
          import(
            /* webpackChunkName: "BusinessCreateSourcing" */ "../views/business/BusinessCreateSourcing.vue"
          ),
      },
      {
        path: "compare",
        name: "BusinessChannelList",
        component: () => import("../views/business/BusinessChannelList.vue")
      },
      {
        path: "compare/report/:id",
        name: "BusinessCompareIGAccountsReport",
        component: () => import("../views/business/BusinessCompareIGAccountsReport.vue")
      }
    ],
  },

  {
    path: "/icons/font/demo",
    name: "AFontExample",
    component: () =>
      import(/* webpackChunkName: "AFontExample" */ "../views/IconsFont"),
  },
  {
    path: "/meta-ads/index",
    name: "MetaAdsIndex",
    component: () =>
      import(/* webpackChunkName: "MetaAdsIndex" */ "../views/meta-ads/MetaAdsIndex"),
  },
  {
    path: "/meta-ads/landing_pages/:id",
    name: "LandingPagesChanges",
    component: () => import("../views/meta-ads/LandingChanges.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");

  if (token !== null) {
    store.commit("makeAuth", true);
  }
  const excludedRoutes = [
    "Home",
    "CreatorLogin",
    "BusinessLogin",
    "SignUp",
    "CreatorSignup",
    "BusinessSignup",
    "CreatorConfirm",
    "BusinessConfirm",
    "Brief",
    "ForgotPassword",
    "RestorePassword",
    "BusinessSignUpSuccessPage",
    "CreatorSignupSuccessPage",
    "AFontExample",
    "Logout",
    "MetaAdsIndex",
    "LandingPagesChanges",
  ];
  if (token === null && !excludedRoutes.includes(to.name)) {
    store.commit("makeAuth", false);

    let local_redirect_url = {
      ...to,
      type: to.name.startsWith("Creator") ? "Creator" : "Business",
    };
    store.commit("set_redirect_route", local_redirect_url);

    store.commit("makeAuth", false);
    return next("/");
  } else {
    return next();
  }
});

export default router;
