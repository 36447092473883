window.CONST = {
  currency_eur: "&euro;",
  currency_eur_string: "€",
  FB_APP_ID: "",
  FB_APP_NAME: "",
  FB_APP_SOCIAL_PROVIDER: "",
  BRIEF_LINK: "/brief/",
  API_SERVER: "",
  DEFAULT_LIMIT: 20,

  DELIVERY_MODE: [
    {
      id: 1,
      name: "POST",
    },
    {
      id: 2,
      name: "STORY",
    },
    {
      id: 3,
      name: "IGTV",
    },
  ],
  get_delivery_mode: (id) => {
    return window.CONST.DELIVERY_MODE.filter((item) => item.id === id)[0].name;
  },

  FILTERS_NAME: [
    {
      key: "delivery_status",
      title: "Delivery status",
    },
  ],
  get_filter_name: (key) => {
    let result_search = window.CONST.FILTERS_NAME.filter(
      (item) => item.key === key
    );
    if (result_search.length) {
      return result_search[0].title;
    }
    return key;
  },

  delivery_status: [
    {
      title: "All",
      id: 0,
    },
    {
      title: "To do",
      id: 1,
    },
    {
      title: "In review",
      id: 2,
    },
    {
      title: "Approved",
      id: 3,
    },
    {
      title: "Rejected",
      id: 4,
    },
    {
      title: "Published",
      id: 5,
    },
  ],
  creator_status: [
    {
      title: "Sourced",
      id: 1,
    },
    {
      title: "Negotiating",
      id: 2,
    },
    {
      title: "Participates",
      id: 3,
    },
    {
      title: "Not interested",
      id: 4,
    },
  ],
  delivery_type: [
    {
      title: "POST",
      id: 1,
    },
    {
      title: "STORY",
      id: 2,
    },
    {
      title: "IGTV",
      id: 3,
    },
  ],
  get_creator_status: (id) => {
    return window.CONST.creator_status.filter((item) => item.id === id)[0]
      .title;
  },
  get_delivery_status: (id) => {
    return window.CONST.delivery_status.filter((item) => item.id === id)[0]
      .title;
  },
  get_delivery_type: (id) => {
    return window.CONST.delivery_type.filter((item) => item.id === id)[0].title;
  },

  convertMediaURL: (url) => {
    if (url === null) {
      return "/images/no-image-2.png";
    }
    if (url.startsWith("http://") || url.startsWith("https://")) {
      return url;
    }
    return window.CONST.API_SERVER.slice(0, -1) + url;
  },
};
